<template>
  <div class="iterationDetail">
    <div class="header">
      <div :class="['header_item', selectIndex === item.id && 'header_item_action', headerList.length < 4 && ('header_item_w')]" :key="item.id" v-for="(item) in headerList" @click="selectFunc(item)">
        <img :src="selectIndex !== item.id ? item.img : item.active_img" alt="" class="header_item_img">
        <div class="header_item_title">{{item.title}}</div>
      </div>
    </div>
    <!-- 选择商品 -->
    <div
        v-if="selectIndex === '1'"
        class="pr item_box"
        style="padding: 0"
        @click="openSelectProDialog"
    >
      <div
          v-if="Object.keys(selectPro).length === 0"
          class="flex flex_align_center"
          style="padding: 0.1rem"
      >
        <SubTitle :title="'选择商品：'" class="flex_item" />
        <img :src="images.iconArrow" alt="" class="icon_arrow" />
      </div>
      <div v-else>
        <DetailListCell :data="selectPro" :isEdit="isEdit" />
      </div>
    </div>
    <!-- 问题类型 -->
    <div class="item_box menu" @click="menuIndex">
      <p class="force">
        <SubTitle :title="'问题类型：'" class="flex_item"/>
        <span class="feedback">{{ menuText }}</span>
      </p>
      <img :src="images.iconArrow" alt="" class="icon_arrow" />
    </div>
    <!-- 问题描述 -->
    <div class="item_box">
      <div class="flex flex_align_center">
        <SubTitle :title="'问题描述：'"/>
      </div>
      <textarea
          placeholder="请输入您要填写的问题描述"
          name=""
          id=""
          cols="30"
          rows="10"
          maxlength="200"
          class="textarea"
          v-model="content"
          :disabled="!isEdit"
      ></textarea>
      <p class="limt-text">还能输入{{ 200 - this.content.length }}/200字</p>
    </div>
    <div class="item_box" v-if="!isEdit && replyContent">
      <div class="service">
        <span class="icon_index"></span>
        <span class="email">客服回复：</span>
      </div>
      <div class="message">{{ replyContent }}</div>
    </div>
    <!-- 上传图片 -->
    <div class="item_box" v-if="selectIndex === '1'">
      <SubTitle :title="'批次号图片'" style="margin-bottom: 0.1rem" />
      <UploadImg
          class="UploadImg"
          :proImgList="piImgList"
          :isEdit="isEdit"
          @addImg="
          (data) => {
            this.piImgList.push(data);
          }
        "
          @removeImg="
          (data) => {
            this.piImgList.forEach((item, index) => {
              if (item.url == data) {
                this.piImgList.splice(index, 1);
                return false;
              }
            });
          }
        "
      />
      <SubTitle :title="'产品图片'" style="margin: 0.1rem 0 0.1rem" />
      <UploadImg
          class="UploadImg"
          :proImgList="proImgList"
          :isEdit="isEdit"
          @addImg="
          (data) => {
            this.proImgList.push(data);
          }
        "
          @removeImg="
          (data) => {
            this.proImgList.forEach((item, index) => {
              if (item.url == data) {
                this.proImgList.splice(index, 1);
                return false;
              }
            });
          }
        "
      />
    </div>
    <div class="item_box" v-else>
      <SubTitle :title="'图片补充'" :isRequired="selectIndex === '2'" style="margin: 0.1rem 0 0.1rem" />
      <UploadImg
          class="UploadImg"
          :proImgList="replenishImgList"
          :isEdit="isEdit"
          @addImg="
          (data) => {
            this.replenishImgList.push(data);
          }
        "
          @removeImg="
          (data) => {
            this.replenishImgList.forEach((item, index) => {
              if (item.url == data) {
                this.replenishImgList.splice(index, 1);
                return false;
              }
            });
          }
        "
      />
    </div>

    <!-- 问题大类 -->
    <van-popup
        v-model="isShowClass"
        :close-on-click-overlay="true"
        round
        position="bottom"
        :style="{ height: '50%' }"
    >
      <div
          class="flex flex_align_center list"
          v-for="(item, index) in classList"
          :key="index"
          @click="selectClass(item.text, index)"
      >
        <div class="flex_item role">{{ index + 1 }}、{{ item.text }}</div>
        <div class="icon_radio" :class="{ ac: item.isSelect }"></div>
      </div>
    </van-popup>
    <!-- 问题二级类目 -->
    <van-popup
        v-model="isShowTwoClass"
        :close-on-click-overlay="true"
        round
        position="bottom"
        :style="{ height: '30%' }"
    >
      <div
          class="flex flex_align_center list"
          v-for="(item, index) in menuList"
          :key="index"
          @click="selectTwoClass(item.questionsMenu, index, item.menuId)"
      >
        <div class="flex_item role">
          {{ index + 1 }}、{{ item.questionsMenu }}
        </div>
        <div class="icon_radio" :class="{ ac: item.isSelect }"></div>
      </div>
    </van-popup>
    <!-- 弹框 -->
    <van-popup
        v-model="isShowProDialog"
        round
        position="bottom"
        :style="{ height: '63%' }"
        class="pop_box"
    >
      <SelectList
          @onSelectPro="onSelectPro"
          :proList="proList"
          @addProList="
          (data) => {
            this.proList = data;
          }
        "
      />
    </van-popup>
    <div v-if="isEdit">
      <button class="btn_submit" @click="submitFeedback">提交</button>
      <router-link to="/feedback">
        <button class="btn_to_list">反馈历史</button>
      </router-link>
    </div>
  </div>
</template>
<script>
import {
  getFeedbackDetail,
  getProList,
  userQuestionsSave,
  getReplayContent,
  getSecondClass,
} from "@/api/feedback/base";
import SubTitle from "./components/SubTitle.vue";
import UploadImg from "./components/UploadImg.vue";
import SelectList from "./components/SelectList.vue";
import DetailListCell from "./components/DetailListCell.vue";
import defaultImg from "@/assets/feedback/default.png";
import { Toast } from "vant";
export default {
  components: {
    SubTitle,
    UploadImg,
    SelectList,
    DetailListCell,
  },
  name: "iterationDetail",
  data() {
    return {
      images: {
        iconArrow: require("@/assets/feedback/fan.png"),
      },
      isShowProDialog: false, //遮罩层
      isEdit: true,
      selectTag: "商品反馈",
      content: "",
      showList: "",
      replenishList: [],
      replenishImgList: [],
      piImgList: [],
      proImgList: [],
      proList: [],
      selectPro: {},
      state: "",
      isShowClass: false,
      selectLoading: false,
      selectFinished: false,
      selectIndex: '1',
      headerList: [
        {
          id: '1',
          title: '商品反馈',
          img: require(`@/assets/feedback/goods_icon.png`),
          active_img: require(`@/assets/feedback/goods_icon2.png`),
        },{
          id: '2',
          title: '服务反馈',
          img: require(`@/assets/feedback/serve_icon.png`),
          active_img: require(`@/assets/feedback/serve_icon2.png`)
        },{
          id: '3',
          title: '功能反馈',
          img: require(`@/assets/feedback/function_icon.png`),
          active_img: require(`@/assets/feedback/function_icon2.png`)
        },{
          id: '4',
          title: '我要吐槽',
          img: require(`@/assets/feedback/other_icon.png`),
          active_img: require(`@/assets/feedback/other_icon2.png`)
        }
      ],
      classList: [
        // {
        //   text: "优化意见（建议）",
        //   isSelect: true,
        // },
        // {
        //   text: "其他",
        // },
        {
          text: "商品反馈",
          isSelect: true,
        },
        // {
        //   text: "小程序bug",
        // },
        // {
        //   text: "活动反馈",
        // },
        // {
        //   text: "物流反馈",
        // },
      ],
      replyContent: "",
      menuId: "10000",
      menuText: "",
      menuList: [],
      isShowTwoClass: false,
    };
  },
  created() {
    this.selectIndex = '1' // 初始化为商品反馈高亮
    Toast.loading({
      message: "加载中",
      forbidClick: true,
      duration: 0,
    });
    let {channelType} = this.$route.query; // 获取来源的渠道
    if(!channelType){
      channelType = 1 // 没有就设置默认成55商城
    }
    sessionStorage.setItem("channelType", channelType)
    if(channelType && channelType == 2){ // 智慧麦酷酷不显示商品反馈
      this.headerList.splice(0, 1) // 删除第一项
      this.selectIndex = this.headerList[0].id
      Toast.clear();
    }else{
      this.loadProList();
    }
  },
  methods: {
    selectFunc(item){
      this.content = ''
      this.menuText = '';
      this.menuId = '';
      this.menuList = []
      this.replenishImgList = []
      this.selectPro = {}
      this.selectIndex = item.id
    },
    //打开订单产品弹层
    openSelectProDialog() {
      if (this.proList.length > 0) {
        this.isShowProDialog = true;
      } else {
        Toast("您暂时无商城订单!");
      }
    },
    loadProList() {
      getProList({
        pageNum: 1,
        pageSize: 5,
      }).then((res) => {
        Toast.clear();
        let { rows } = res;
        this.proList = rows;
      });
    },
    onSelectPro(index, ins) {
      this.selectPro = {};
      for (let i = 0; i < this.proList.length; i++) {
        this.proList[i].children.forEach((item) => {
          this.$set(item, "isSelect", false);
        });
      }
      this.$set(this.proList[index].children[ins], "isSelect", true);
      this.isShowProDialog = false;

      const { parentOrderNo, children } = this.proList[index];
      const { image, goodsTitle, skuAttr, ctime, buyNum, goodsId, orderId } =
          children[ins];
      this.selectPro = {
        parentOrderNo,
        image: image ? image : defaultImg,
        goodsTitle,
        skuAttr,
        ctime,
        buyNum,
        goodsId,
        orderId,
      };
      Toast.loading({
        message: "加载中",
        forbidClick: true,
        duration: 0,
      });
      getSecondClass({ goodsId }).then((res) => {
        if (res.data == "") {
          Toast.fail("没有获取到二级类目!");
        }
        Toast.clear();
        this.menuList = res.data;
      });
    },
    loadFeedbackDetail(id) {
      getFeedbackDetail(id).then((res) => {
        let { data } = res;
        const {
          parentOrderNo,
          image,
          goodsTitle,
          skuAttr,
          ctime,
          buyNum,
          content,
          piciImg,
          proImg,
          state,
          questionsMenu,
        } = data;

        this.selectPro = {
          parentOrderNo,
          image,
          goodsTitle,
          skuAttr,
          ctime,
          buyNum,
        };
        this.selectTag = "商品反馈";
        this.menuText = questionsMenu;
        this.content = content;
        this.piImgList = this.getPiImgListFire(piciImg);
        this.proImgList = this.getPiImgListFire(proImg);
        this.state = state;
      });
    },
    submitFeedback() {
      if (!this.menuId) {
        Toast("请选择问题类型~");
        return false;
      }
      if (!this.content) {
        Toast("请输入您要填写的问题描述~");
        return false;
      }
      if (this.selectIndex === '1') { // 只有商品反馈有
        if (Object.keys(this.selectPro).length === 0) {
          Toast("请先选择产品哦~");
          return false;
        }
        if (this.piImgList.length == 0) {
          Toast("请添加批次号图片");
          return false;
        }
        if (this.proImgList.length == 0) {
          Toast("请添加产品图片");
          return false;
        }
      } else {
        this.selectPro = {} // 清空选择的商品
        if(this.selectIndex == 2 && this.replenishImgList.length == 0){
            Toast("请添加图片补充");
            return false;
        }
    }
      const {
        buyNum,
        goodsTitle,
        image,
        parentOrderNo,
        ctime,
        skuAttr,
        goodsId,
        orderId,
      } = this.selectPro;
      let obj = {
        parentOrderNo,
        buyNum,
        goodsTitle,
        image,
        ctime,
        skuAttr,
        goodsId,
        orderId,
        typeNo: this.selectIndex === '1' ? 3 : '', // 商品反馈就写死 3
        classification: this.selectIndex, // 反馈类型：1商品反馈，2服务反馈，3功能反馈，4其他反馈
        content: this.content,
        piciImg: JSON.stringify(this.getPiImgList('piImgList')),
        proImg: JSON.stringify(this.getPiImgList('proImgList')),
        addImg: this.selectIndex !== '1' ? JSON.stringify(this.getPiImgList('replenishImgList')) : '',
        userId: sessionStorage.getItem("userId"),
        menuId: this.menuId,
        channelType: sessionStorage.getItem("channelType"),
      }
      if(this.selectIndex === '1'){
        delete obj.addImg
      }else{
        delete obj.proImg
        delete obj.piciImg
      }
      userQuestionsSave(obj).then(() => {
        Toast.success("提交成功");
        this.$router.push({
          path: "/feedback"
        });
      });
    },
    getPiImgList(str) {
      let arr = [];
      this[str].forEach((item) => {
        arr.push(item.url);
      });
      return arr;
    },
    getPiImgListFire(piciImg) {
      if (piciImg) {
        let arr = [];
        JSON.parse(piciImg).forEach((item) => {
          arr.push({
            url: item,
          });
        });
        return arr;
      }
    },
    listBox() {
      if (this.isEdit) this.isShowClass = true;
    },
    menuIndex() {
      if(this.selectIndex === '1'){
        if (this.isEdit) this.isShowTwoClass = true;
        return false
      }
      Toast.loading({
        message: "加载中",
        forbidClick: true,
        duration: 0,
      });
      getSecondClass({ classification: this.selectIndex }).then((res) => {
        if (res.data == "") {
          Toast.fail("没有获取到二级类目!");
        }
        Toast.clear();
        if (this.isEdit) this.isShowTwoClass = true;
        res.data.map(val => {
          if(val.menuId === this.menuId){
            val.isSelect = true
          }
        })
        this.menuList = res.data;
      });
    },
    selectClass(text, index) {
      this.selectTag = text;
      this.classList.forEach((item) => {
        this.$set(item, "isSelect", false);
      });
      this.$set(this.classList[index], "isSelect", true);
      this.isShowClass = false;
    },
    loadReplayContent(id) {
      getReplayContent(id).then((res) => {
        if (res.data.length > 0) {
          this.replyContent = res.data[0].replyContent;
        }
      });
    },
    selectTwoClass(text, index, menuId) {
      this.menuText = text;
      this.menuId = menuId;
      this.menuList.forEach((item) => {
        this.$set(item, "isSelect", false);
      });
      this.$set(this.menuList[index], "isSelect", true);
      this.isShowTwoClass = false;
    },
  },
};
</script>
<style lang="less" scoped>
.iterationDetail {
  min-height: 100vh;
  .header{
    display: flex;
    //justify-content: space-between;
    padding: 0.2rem 0.2rem 0.1rem;
    box-sizing: border-box;
    .header_item{
      width: 0.75rem;
      height: 0.75rem;
      margin-right: 0.12rem;
      background: #fff;
      color: #666666;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &:last-child{
        margin-right: 0;
      }
      .header_item_img{
        height: 0.19rem;
        width: 0.19rem;
        margin-bottom: 0.085rem;
      }
      .header_item_title{
        text-align: center;
        width: 100%;
      }
    }
    .header_item_w{
      width: 1.2rem;
    }
    .header_item_action{
      position: relative;
      background: #DE2727;
      color: #FFFFFF!important;
      &::after{
        content: '';
        position: absolute;
        bottom: -0.155rem;
        left: 50%;
        border-left: 0.08rem solid transparent;
        border-right: 0.08rem solid transparent;
        border-top: 0.08rem solid #DE2727;
        border-bottom: 0.08rem solid transparent;
        transform: translateX(-50%);
      }
    }
  }
  .issue {
    display: block;
    font-size: 0.14rem;
    font-weight: 700;
  }
  .list {
    height: 0.19rem;
    padding: 0.23rem 0.2rem;
    border-bottom: 0.01rem solid #e5e5e5;
    .role {
      font-size: 0.14rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      opacity: 1;
    }
  }
  .icon_radio {
    background: url("../../assets/feedback/radio.png") no-repeat;
    background-size: 100% 100%;
    width: 0.15rem;
    height: 0.15rem;
    margin-left: 0.4rem;
    &.ac {
      background: url("../../assets/feedback/radio-select.png");
      background-size: 100% 100%;
      width: 0.15rem;
      height: 0.15rem;
    }
  }
  .item_box {
    background: #fff;
    margin: 0.1rem 0.1rem 0 0.1rem;
    padding: 0.1rem;
    border-radius: 0.07rem;
    margin-bottom: 0.05rem;
    position: relative;
    .service {
      display: flex;
      height: 0.28rem;
      align-items: center;
      .icon_index {
        width: 0.3rem;
        height: 0.3rem;
        background: url("../../assets/feedback/tel.png") no-repeat;
        background-size: 0.3rem;
      }
      .email {
        font-size: 0.13rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #666666;
        opacity: 1;
        margin-left: 0.1rem;
      }
    }
    .message {
      width: 3.35rem;
      overflow-y: auto;
      background: rgba(234, 240, 250, 0.39);
      height: 1rem;
      box-sizing: border-box;
      border-radius: 0.1rem;
      padding: 0.1rem;
      font-size: 0.12rem;
      border: none;
      line-height: 0.2rem;
    }
  }
  .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .force {
      display: flex;
      align-items: center;
      .feedback {
        font-size: 0.14rem;
        font-family: PingFangSC-Regular;
        color: #000000;
        font-weight: 700;
        opacity: 1;
      }
    }
    .mask {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9999;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
  .choice {
    height: 0.21rem;
    padding: 0rem 0.25rem;
    font-size: 0.01rem;
    color: #999999;
  }
  .icon_arrow {
    width: 0.12rem;
    height: 0.06rem;
  }

  .textarea {
    width: 3.35rem;
    height: 1rem;
    box-sizing: border-box;
    border-radius: 0.1rem;
    padding: 0.1rem;
    font-size: 0.12rem;
    margin-top: 0.1rem;
    border: 1px solid #999;
    line-height: 0.2rem;
  }
  .limt-text {
    text-align: right;
    position: absolute;
    right: 0.2rem;
    bottom: 0.2rem;
    color: #999999;
    font-size: 0.12rem;
  }
  .btn_submit {
    width: 1.75rem;
    height: 0.4rem;
    border-radius: 0.2rem;
    background: #dc2727;
    line-height: 0.4rem;
    text-align: center;
    margin: 0.35rem auto;
    display: block;
    border: none;
    font-size: 0.18rem;
    color: #fff;
  }
  .btn_to_list {
    font-size: 0.12rem;
    color: #ea7c6c;
    text-align: center;
    border: none;
    margin: 0.4rem auto 0.2rem;
    display: block;
    text-decoration: underline;
  }
  .pop_box {
    padding: 0.15rem 0 0;
    box-sizing: border-box;
  }
  .state {
    right: 0;
    top: 0;
    background: #fae2df;
    border-radius: 0 0.07rem;
    font-size: 0.1rem;
    color: #dc2727;
    text-align: center;
    width: 0.41rem;
    height: 0.2rem;
    line-height: 0.2rem;
    &.online {
      background: #e5efff;
      color: #246ede;
    }
  }
}
</style>
